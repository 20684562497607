import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid py-3 footer bg-dark-subtle" }
const _hoisted_2 = { class: "d-flex justify-content-center gap-4" }
const _hoisted_3 = { class: "link-icons d-flex justify-content-center gap-4 text-center" }
const _hoisted_4 = {
  href: "https://feedback.alcalyn.app",
  target: "_blank"
}
const _hoisted_5 = {
  href: "https://discord.gg/59SJ9KwvVq",
  target: "_blank"
}
const _hoisted_6 = {
  href: "https://github.com/playhex/playhex",
  target: "_blank"
}
const _hoisted_7 = { class: "infos" }
const _hoisted_8 = { key: 0 }

import { BIconBookmarks, BIconChatDots, BIconGithub, BIconDiscord } from 'bootstrap-icons-vue';
import { ref } from 'vue';
import { seo } from '../../../../shared/app/seo.js';
import { format } from 'date-fns';
import i18next from 'i18next';

/* global LAST_COMMIT_DATE */
// @ts-ignore: LAST_COMMIT_DATE replaced at build time by webpack.

export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {

const lastCommitDate: string = LAST_COMMIT_DATE;

const date = ref<string>(lastCommitDate);

i18next.on('languageChanged', () => {
    date.value = format(new Date(`${lastCommitDate} 12:00:00`), 'd MMM y');
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_unref(seo).title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", _hoisted_4, [
        _createVNode(_unref(BIconChatDots), { class: "text-body" }),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback')), 1)
      ]),
      _createElementVNode("a", _hoisted_5, [
        _createVNode(_unref(BIconDiscord), { class: "text-body" }),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _cache[2] || (_cache[2] = _createTextVNode(" Discord "))
      ]),
      _createElementVNode("a", _hoisted_6, [
        _createVNode(_unref(BIconGithub), { class: "text-body" }),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _cache[4] || (_cache[4] = _createTextVNode(" GitHub "))
      ]),
      _createVNode(_component_router_link, { to: { name: 'links' } }, {
        default: _withCtx(() => [
          _createVNode(_unref(BIconBookmarks), { class: "text-body" }),
          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('hex_links')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("p", _hoisted_7, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('project_under_development')), 1),
      (date.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('last_change', { date: date.value })), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, { to: { name: 'guide' } }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("PlayHex guide")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'contribute' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('contribute')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'contributors' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('contributors')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'rescue' } }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("Rescue page")
        ])),
        _: 1
      }),
      _cache[8] || (_cache[8] = _createElementVNode("a", {
        href: "https://stats.uptimerobot.com/mJrbJF1nfb",
        target: "_blank"
      }, "Server status", -1)),
      _createVNode(_component_router_link, { to: { name: 'privacy' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('privacy_policy')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'license' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('license_agpl')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})