import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fst-italic"
}
const _hoisted_2 = { class: "text-body-secondary ms-2 d-inline-block" }

import Player from '../../../shared/app/models/Player.js';
import { PropType } from 'vue';
import { RouterLink } from 'vue-router';
import AppOnlineStatus from './AppOnlineStatus.vue';
import { glicko2Settings, isRatingConfident } from '../../../shared/app/ratingUtils.js';
import usePlayersStore from '../../stores/playersStore.js';
import { ref } from 'vue';
import { watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppPseudo',
  props: {
    player: {
        type: Object as PropType<Player>,
        required: true,
    },
    is: {
        type: String,
        default: 'span',
    },
    classes: {
        type: [String, Array, Object] as PropType<null | string | { [className: string]: boolean } | string[]>,
        default: null,
    },

    /**
     * Whether to display a green/grey circle on username to show player presence/absence
     */
    onlineStatus: {
        type: Boolean,
        default: false,
    },

    /**
     * How to display rating:
     * - `<AppPseudo />` If not set, rating is not shown.
     * - `<AppPseudo rating />` rating is shown in minimalist form, like "1500" or "~1500" depending on confidence
     * - `<AppPseudo rating="full" />` rating is fully shown, like "1500 ±140"
     */
    rating: {
        type: [Boolean, String] as PropType<boolean | 'full'>,
        default: false,
    },
},
  setup(__props) {

const props = __props;

const { round } = Math;
const p = ref();
watchEffect(() => {
    p.value = usePlayersStore().playerRef(props.player);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.onlineStatus)
      ? (_openBlock(), _createBlock(AppOnlineStatus, {
          key: 0,
          player: p.value
        }, null, 8, ["player"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(RouterLink), {
      to: p.value.slug ? { name: 'player', params: { slug: p.value.slug } } : '',
      class: "text-body text-decoration-none"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(__props.is), {
          class: _normalizeClass(__props.classes)
        }, {
          default: _withCtx(() => [
            (p.value.isGuest)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('guest')) + " ", 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(p.value.pseudo), 1)
          ]),
          _: 1
        }, 8, ["class"])),
        (__props.rating)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "small" }, " ", -1)),
              _createElementVNode("small", _hoisted_2, [
                ('full' === __props.rating)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_unref(round)(p.value.currentRating?.rating ?? _unref(glicko2Settings).rating)) + " ±" + _toDisplayString(_unref(round)((p.value.currentRating?.deviation ?? _unref(glicko2Settings).rd) * 2)), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (p.value.currentRating ? !_unref(isRatingConfident)(p.value.currentRating) : true)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode("~")
                          ], 64))
                        : _createCommentVNode("", true),
                      _createTextVNode(_toDisplayString(_unref(round)(p.value.currentRating?.rating ?? _unref(glicko2Settings).rating)), 1)
                    ], 64))
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"])
  ], 64))
}
}

})