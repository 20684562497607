import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Player from '../../../shared/app/models/Player.js';
import useOnlinePlayersStore from '../../stores/onlinePlayersStore.js';
import { PropType } from 'vue';
import { BIconCircleFill, BIconMoonFill, BIconRobot } from 'bootstrap-icons-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppOnlineStatus',
  props: {
    player: {
        type: Object as PropType<Player>,
        required: true,
    },
},
  setup(__props) {

const props = __props;

const onlinePlayersStore = useOnlinePlayersStore();

return (_ctx: any,_cache: any) => {
  return (props.player.isBot)
    ? (_openBlock(), _createBlock(_unref(BIconRobot), {
        key: 0,
        class: "me-1 text-success",
        "aria-hidden": "true"
      }))
    : (!_unref(onlinePlayersStore).isPlayerOnline(props.player.publicId))
      ? (_openBlock(), _createBlock(_unref(BIconCircleFill), {
          key: 1,
          class: "online-status-icon text-secondary",
          "aria-hidden": "true"
        }))
      : (_unref(onlinePlayersStore).isPlayerActive(props.player.publicId))
        ? (_openBlock(), _createBlock(_unref(BIconCircleFill), {
            key: 2,
            class: "online-status-icon text-success",
            "aria-hidden": "true"
          }))
        : (_openBlock(), _createBlock(_unref(BIconMoonFill), {
            key: 3,
            class: "online-status-icon text-warning",
            "aria-hidden": "true"
          }))
}
}

})