import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "toast-container position-fixed bottom-0 end-0" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "toast-body" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

import { storeToRefs } from 'pinia';
import useToastsStore from '../../../stores/toastsStore.js';
import { Toast } from '../../../../shared/app/Toast.js';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppToasts',
  setup(__props) {

const { toasts } = storeToRefs(useToastsStore());
const { deleteToast } = useToastsStore();
const router = useRouter();

/**
 * When click on the toast itself (not buttons or close button)
 */
const clickToast = (toast: Toast): void => {
    const { actions } = toast.options;

    // no action: just close it
    if (0 === actions.length) {
        deleteToast(toast);
        return;
    }

    // single action: do it
    if (1 === actions.length) {
        const { action } = actions[0];

        if ('function' === typeof action) {
            action();
            deleteToast(toast);
            return;
        }

        router.push(action);
        deleteToast(toast);
        return;
    }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toasts), (toast) => {
      return (_openBlock(), _createElementBlock("div", {
        key: toast.id,
        onClick: ($event: any) => (clickToast(toast)),
        class: _normalizeClass(["toast align-items-center border-0 cursor-pointer", [
                'text-bg-' + toast.options.level,
                {
                    show: toast.show,
                    clickable: toast.options.actions.length < 2,
                },
            ]]),
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true",
        "data-bs-animation": "true"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(toast.message) + " ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(toast.options.actions, ({ label, action }, key) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                (('function' === typeof action))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-link",
                      onClick: _withModifiers(() => { action(); _unref(deleteToast)(toast); }, ["stop"])
                    }, _toDisplayString(label), 9, _hoisted_5))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      class: "text-body",
                      to: action,
                      onClick: _withModifiers(($event: any) => (_unref(deleteToast)(toast)), ["stop"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(label), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "onClick"]))
              ], 64))
            }), 128))
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close btn-close-white me-2 m-auto",
            "data-bs-dismiss": "toast",
            "aria-label": "Close",
            onClick: _withModifiers(($event: any) => (_unref(deleteToast)(toast)), ["stop"])
          }, null, 8, _hoisted_6)
        ])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
}

})